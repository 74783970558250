@use "./root" as *;

.more-info-hero-section {
  height: 80vh;
  min-height: rem(500);
  padding-top: rem(140);

  @media (min-width: 48em) {
    // height: 100vh;
  }

  @media (min-width: 48em) {
    padding-top: max(25vh, rem(200));
  }

  @media (min-width: 96em) {
    min-height: rem(1000);
    padding-top: rem(400);
  }
}

.publication-section {
  margin-bottom: rem(-180);
  position: relative;
  #deco {
    position: absolute;
    top: 50%;
    left: -40;
  }
  &__links-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  @media (min-width: 48em) {
    &__links-container {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      gap: min(10vw, rem(120));
    }
  }

  &__links-wrapper {
    background-color: $clr-neutral-800;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: rem(50);
    margin-block-end: rem(100);

    p,
    span {
      color: white;
    }

    & > p {
      margin-bottom: rem(50);
      text-transform: uppercase;
      letter-spacing: rem(4);
      font-weight: $fw-light;
    }
  }

  &__link-wrapper {
    width: calc(100% - rem(40));
    position: relative;
    max-width: rem(400);
    margin-block-end: rem(50);

    p {
      max-width: 35ch;
      text-align: center;
      margin-inline: auto;
    }
  }

  &__upper-link {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    height: rem(150);
    position: relative;
    background-color: black;
    margin-block-end: rem(25);
    overflow: hidden;
    cursor: pointer;

    &:hover .publication-section__rect,
    &:active .publication-section__rect,
    &:focus .publication-section__rect {
      width: rem(200);
      background-color: $clr-primary-400;
      transition-delay: 0s;
    }

    &:hover .publication-section__triangle,
    &:active .publication-section__triangle,
    &:focus .publication-section__triangle {
      fill: $clr-primary-400;
      transition-delay: 0s;
    }

    &:hover .publication-section__rect,
    &:active .publication-section__rect,
    &:focus .publication-section__rect {
      width: rem(200);
      background-color: $clr-primary-400;
      transition-delay: 0s;
    }

    &:hover .publication-section__triangle {
    }

    &:hover .publication-section__text span:first-child {
      color: white;
    }

    img {
      height: 100%;
      width: 80%;
      object-fit: cover;
    }
  }

  &__rect {
    position: absolute;
    width: rem(100);
    top: 0;
    left: 0;
    height: 100%;
    background-color: $clr-neutral-800;
    transition: 0.5s all cubic-bezier(0.85, 0, 0.12, 1) 0.25s;
  }

  &__triangle {
    position: absolute;
    top: 0;
    left: 100%;
    height: 100%;
    width: auto;
    max-width: unset;
    transform: translate(rem(-0.5), rem(-0.2));
    fill: $clr-neutral-800;
    transition: 0.5s all cubic-bezier(0.85, 0, 0.12, 1) 0.25s;
  }

  &__text {
    position: absolute;
    top: 50%;
    left: 6%;
    display: flex;
    flex-direction: column;
    transform: translateY(-50%);

    span {
      text-transform: uppercase;
      font-weight: $fw-black;
      font-size: $fs-500;
    }

    span:first-child {
      color: $clr-primary-400;
      font-weight: $fw-light;
      font-size: $fs-400;
      transition: all 0.6s cubic-bezier(0.85, 0, 0.12, 1);
      margin-bottom: rem(5);
    }
  }

  //----------------------------------------------

  &__container {
    margin-top: rem(120);
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    gap: rem(80);
    width: calc(100% - rem(40));
    margin-inline: auto;
  }

  &__wrapper {
    background-color: $clr-primary-200;
    width: 100%;
    max-width: rem(340);
    height: rem(400);

    overflow: hidden;
    position: relative;
    box-shadow:
      0 10px 20px hsla(0, 0%, 0%, 0.05),
      0 3px 6px hsl(0, 0%, 0%, 0.1);
    z-index: 10;

    a {
      position: absolute;
      inset: 0;
      padding: rem(25) rem(20);
      text-decoration: none;
      color: $clr-neutral-800;
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      box-shadow: inset 0 -10px 0 0 var(--color);
      z-index: 3;
      transition: box-shadow 0.5s ease-in-out;
      pointer-events: none;
    }
    &::after {
      content: "";
      position: absolute;
      inset: 0;
      transition: background-color 0.3s ease-in;
      z-index: 1;
      pointer-events: none;
    }

    &:hover {
      cursor: pointer;
      .publication-section__tag {
        font-size: rem(12);
        padding: rem(10) rem(8) rem(8) rem(8);
      }
      .publication-section__img {
        scale: 1.3;
        rotate: 10deg;
      }
      .publication-section__title {
        margin-top: rem(20);
        font-size: rem(28);
      }

      .publication-section__description {
        font-size: rem(17);
      }

      &:after {
        background-color: hsla(216, 8%, 25%, 0.205);
      }
    }
  }

  $ease: cubic-bezier(0.85, 0, 0.12, 1);
  $ease-time: 0.5s;
  &__tag {
    background-color: var(--color);
    color: white;
    padding: rem(12) rem(10) rem(10) rem(10);
    font-size: $fs-300;
    transition: all $ease-time $ease;
  }
  &__title {
    margin-top: rem(35);
    font-size: $fs-550;
    font-weight: $fw-regular;
    margin-bottom: rem(-8);
    transition: all $ease-time $ease;
  }

  &__description {
    font-size: $fs-475;
    color: $clr-neutral-400;
    transition: font-size $ease-time $ease;
  }

  &__img {
    width: rem(190);
    height: rem(250);
    object-fit: cover;
    rotate: 20deg;
    position: absolute;
    z-index: 2;
    bottom: rem(-80);
    left: rem(-10);
    box-shadow:
      0 10px 20px hsla(0, 0%, 0%, 0.15),
      0 3px 6px hsl(0, 0%, 0%, 0.1);
    transition: all $ease-time $ease;
  }

  &__img-wrapper {
    position: absolute;
    inset: 0 0 5 0;
    overflow: hidden;
  }
}
