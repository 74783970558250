@use "./normalize.css";
@use "./root";
@use "./fonts";
@use "./utils";
@use "./nav";
@use "./home";
@use "./story";
@use "./videos";
@use "./more-infos";
@use "./contact";
@use "./components/title";
@use "./components/footer";
