@use "root" as *;

//-------------------HOME SECTION--------------------------

.hero-section {
  position: relative;
  overflow: hidden;
  height: 80vh;
  min-height: rem(750);

  @media screen and (min-width: 48em) {
    height: 100vh;
    min-height: rem(900);
  }

  @media screen and (min-width: 64em) {
  }

  @media screen and (min-width: 80em) {
    background-color: #eff0f0;
    min-height: 50vw;
    padding-top: rem(40);
  }

  // @media screen and (min-width: 96em) {
  //   // min-height: rem(1000);
  // }

  @media screen and (min-width: 150em) {
    min-height: rem(1200);
  }

  &__rect {
    position: absolute;
    width: calc((100% - rem(950)) / 2);
    top: 0;
    right: 0;
    height: 100%;
    background-color: $clr-neutral-100;
    @media screen and (min-width: 80em) {
      background-color: white;
    }
  }

  &__triangle {
    position: absolute;
    top: 0;
    right: 100%;
    height: 100%;
    width: auto;
    max-width: unset;
    transform: translate(rem(0.5), rem(0.5));
    fill: $clr-neutral-100;
    @media screen and (min-width: 80em) {
      fill: white;
    }
  }

  &__triangle-menu {
    position: absolute;
    top: 0;
    right: 100%;
    height: 100%;
    width: auto;
    max-width: unset;
    transform: translate(rem(0.5), rem(0.5));
    fill: $clr-neutral-100;
  }

  &__hero-wrapper {
    position: relative;
    top: 7%;

    width: 100%;
    max-width: rem(2500);
    margin-inline: auto;
    display: flex;
    justify-content: center;

    @media screen and (min-width: 80em) {
      top: 15%;
      height: 100%;
      justify-content: flex-end;
      left: min(5%, rem(125));
    }

    @media screen and (min-width: 96em) {
      top: 10%;
    }
  }

  &__img {
    width: 110%;
    max-width: rem(500);
    // clip-path: url(#myClip);

    @media screen and (min-width: 80em) {
      position: absolute;
      top: 0;
      right: 0;
      max-width: rem(1500);
      width: 60vw;
      z-index: 1;
    }
  }

  &__text-wrapper {
    position: absolute;
    top: 110%;
    margin-top: min(5vw, rem(125));
    left: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    transform: translateX(-50%);

    @media screen and (min-width: 80em) {
      top: 0;
      left: 0;
      transform: unset;
      align-items: flex-start;
    }
  }

  &__h2 {
    position: relative;
    margin-bottom: -1.6rem;
    font-size: 2.5rem;
    text-transform: uppercase;
    font-weight: $fw-bold;

    @media screen and (min-width: 80em) {
      mix-blend-mode: difference;
      color: white;
      font-size: min(6vw, rem(150));
      margin-bottom: max(-5vw, rem(-125));
      margin-left: -0.2vw;
      &::before {
        mix-blend-mode: unset;
        content: "#17";
        position: absolute;
        top: 11vw;
        right: -22vw;
        font-size: calc(10vw + 7rem);
        color: transparent;
        font-weight: $fw-black;
        -webkit-text-stroke-width: rem(1);
        -webkit-text-stroke-color: #afb2b63f;
        z-index: 0;
      }
    }
  }

  &__h1 {
    font-size: 4rem;
    text-transform: uppercase;
    font-weight: $fw-black;
    position: relative;
    z-index: 2;

    @media screen and (min-width: 80em) {
      mix-blend-mode: difference;
      color: white;
      font-size: min(10vw, rem(250));
      margin-bottom: -2rem;
      margin-left: max(-0.7vw, rem(-15));
    }
  }

  &__span {
    font-size: 1rem;
    text-transform: uppercase;
    padding: 1rem 1rem 0;
    border-top: rem(2) solid black;
    font-weight: $fw-bold;

    @media screen and (min-width: 80em) {
      mix-blend-mode: difference;
      color: white;
      padding: 1rem 1rem 0 0;
      border-top: 0.2vw solid white;
      font-size: min(1.3vw, rem(33));
    }
  }
}

//-------------------SKILL SECTION--------------------------

.skill-section {
  padding-top: 10rem;
  padding-bottom: 20rem;

  &__main-wrapper {
    position: relative;
    width: calc(100% - rem(40));
    margin-inline: auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    & > .img-wrapper {
      position: relative;
      display: none;

      @media screen and (min-width: 80em) {
        display: flex;
        width: rem(300);
        height: rem(500);
      }
      img {
        position: absolute;
        top: 60;
        width: rem(300);
        &:nth-child(1) {
          z-index: 2;
        }
        &:nth-child(2) {
          top: rem(-42);
          left: rem(16);
          // min-width: 107%;
          width: rem(265);
          opacity: 0;
        }
      }
    }

    @media screen and (min-width: 80em) {
      display: flex;
      flex-direction: row;
      justify-content: center;
      gap: 10rem;
    }

    @media screen and (min-width: 96em) {
      gap: 17rem;
    }
  }

  &__small-img {
    max-width: rem(180);
    height: auto;
    margin-top: -2rem;
    @media screen and (min-width: 80em) {
      display: none;
    }
  }

  &__wide-img {
    display: none;

    @media screen and (min-width: 80em) {
      display: flex;
      width: rem(300);
    }
  }

  &__stats {
    margin-top: 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: rem(60);

    @media screen and (min-width: 80em) {
      align-items: unset;
      width: unset;
      margin-top: 4rem;
    }
  }

  &__upper-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: flex-start;
    width: 100%;
    max-width: rem(500);

    .img-wrapper {
      display: block;
      position: relative;
      width: rem(180);
      height: rem(300);

      @media screen and (min-width: 80em) {
        display: none;
      }
      img {
        position: absolute;
        top: 40;

        &.first-img-m {
          z-index: 2;
        }
        &.second-img-m {
          top: rem(-20);
          left: rem(9);
          // min-width: 107%;
          max-width: rem(158);
          opacity: 0;
        }
      }
    }

    @media screen and (min-width: 80em) {
      align-items: unset;
      justify-content: flex-start;
      padding-bottom: rem(40);
      border-bottom: 1px solid #eff0f0;
    }
  }

  &__under-wrapper {
    width: 100%;
    max-width: rem(600);

    @media screen and (min-width: 96em) {
      display: flex;
      gap: rem(80);
      max-width: unset;
    }
  }

  &__inner-wrapper {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media screen and (min-width: 80em) {
      flex-direction: row;
      justify-content: flex-start;

      & .skill-section__position {
        order: 2;
      }

      & .skill-section__shirt {
        order: 1;
      }

      & .skill-section__foot {
        order: 3;
      }
    }
  }

  &__shirt,
  &__position,
  &__foot {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }

  &__tag {
    font-size: $fs-300;
    color: white;
    padding: rem(5) rem(10) rem(4) rem(9);
    background-color: $clr-primary-400;
    margin-bottom: 0.5rem;
    border-radius: rem(1);
    font-weight: $fw-light;
    text-transform: uppercase;
  }

  &__left-wrapper,
  &__right-wrapper {
    display: flex;
    width: min(90%, rem(350));
    justify-content: space-between;
    position: relative;
    margin-inline: auto;

    // .skill-section__value {
    //   max-width: 15ch;
    // }

    @media screen and (min-width: 48em) {
      gap: rem(170);
      width: unset;
      justify-content: center;
    }

    @media screen and (min-width: 64em) {
      gap: rem(200);
    }

    @media screen and (min-width: 80em) {
      gap: rem(120);
      width: unset;
    }

    @media screen and (min-width: 96em) {
      gap: 4rem;
    }
  }

  &__left-wrapper {
    margin-bottom: 3rem;
  }

  &__values {
    width: rem(135);
    @media screen and (min-width: 48em) {
      width: rem(185);
    }
    @media screen and (min-width: 80em) {
      width: rem(185);
    }
  }

  &__names {
    position: relative;
    color: $clr-neutral-400;
    width: rem(90);

    &.personendaten {
      &::before {
        content: "Personendaten";
        position: absolute;
        left: 0;
        top: rem(-45);
        font-size: $fs-300;
        color: white;
        padding: rem(5) rem(10) rem(4) rem(9);
        background-color: $clr-primary-400;
        border-radius: rem(1);
        font-weight: $fw-light;
        text-transform: uppercase;
      }
    }

    &.privat {
      &::before {
        content: "Privat";
        position: absolute;
        left: 0;
        top: rem(-45);
        font-size: $fs-300;
        color: white;
        padding: rem(5) rem(10) rem(4) rem(9);
        background-color: $clr-primary-400;
        border-radius: rem(1);
        font-weight: $fw-light;
        text-transform: uppercase;
      }
    }
  }

  &__names,
  &__values {
    display: flex;
    flex-direction: column;
    margin-top: 2rem;
    gap: rem(12);
  }

  @media screen and (min-width: 64em) {
  }
}

@media screen and (min-width: 48em) {
}

@media screen and (min-width: 64em) {
}

@media screen and (min-width: 80em) {
}

@media screen and (min-width: 96em) {
}

//-------------------SPONSOR SECTION--------------------------

.sponsor-section {
  background-color: $clr-neutral-100;
  display: flex;
  flex-direction: column;
  align-items: center;
  // margin-bottom: -20rem;
  // z-index: -2;
  position: relative;
  // padding-top: rem(80);
  padding-bottom: rem(150);

  #deinLogoSvg {
    position: absolute;
    z-index: 2;
    top: 25;
    right: -40;
    // top: 5;
    // right: -50;
    path {
      fill: $clr-neutral-100;
      &:last-child {
        fill: unset;
      }
    }
  }
  &__logo-wrapper {
    background-color: $clr-neutral-800;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    align-items: center;
    width: 80%;
    margin-inline: auto;
    transform: translateY(-13rem);
    margin-bottom: -7rem;
    padding: 2rem 0;
    max-width: rem(1200);
    box-shadow:
      0 10px 20px hsla(0, 0%, 0%, 0.25),
      0 3px 6px hsl(0, 0%, 0%, 0.15);
    div {
      width: rem(120);
      padding: 2rem 0;
      margin: 0 2rem;
      @media screen and (min-width: 80em) {
        width: rem(120);
      }
    }

    div:nth-child(1) {
      width: rem(120);
      height: auto;
      @media screen and (min-width: 80em) {
        width: rem(180);
        margin-right: rem(-20);
      }
    }

    div:nth-child(2) {
      width: rem(120);
      height: auto;
      @media screen and (min-width: 80em) {
        width: rem(180);
        // margin-right: rem(-20);
      }
    }
    div:nth-child(3) {
      position: relative;
      width: rem(130);
    }
  }

  &__main-wrapper {
    width: calc(100% - rem(40));
    max-width: rem(500);
    position: relative;
    z-index: 1;
    margin-inline: auto;
  }

  &__title-sub {
    font-size: $fs-475;
    font-weight: $fw-bold;

    &:nth-child(1) {
      position: relative;

      &::before {
        content: "00";
        position: absolute;
        top: rem(-20);
        left: rem(-40);
        font-size: rem(90);
        color: transparent;
        font-weight: $fw-black;
        -webkit-text-stroke-width: rem(1);
        -webkit-text-stroke-color: $clr-neutral-200;
        z-index: -1;
      }
    }

    &:nth-child(3) {
      position: relative;

      &::before {
        content: "01";
        position: absolute;
        top: rem(-20);
        left: rem(-40);
        font-size: rem(90);
        color: transparent;
        font-weight: $fw-black;
        -webkit-text-stroke-width: rem(1);
        -webkit-text-stroke-color: $clr-neutral-200;
        z-index: -1;
      }
    }

    &:nth-child(5) {
      position: relative;

      &::before {
        content: "02";
        position: absolute;
        top: rem(-20);
        left: rem(-40);
        font-size: rem(90);
        color: transparent;
        font-weight: $fw-black;
        -webkit-text-stroke-width: rem(1);
        -webkit-text-stroke-color: $clr-neutral-200;
        z-index: -1;
      }
    }
  }

  &__text {
    color: $clr-neutral-400;
    border-bottom: rem(1) solid #c9ced6;
    padding-bottom: 3rem;
    margin-bottom: 3rem;
    z-index: 5;
    line-height: 1.8;
    margin-top: 0.5rem;
    hyphens: auto;
    &:last-child {
      margin-bottom: 3rem;
      border-bottom: unset;
    }
  }

  &__button-primary {
    text-decoration: none;
    color: white;
    background-color: $clr-primary-400;
    padding: rem(27) rem(40) rem(25);
    line-height: 0;
    cursor: pointer;
    box-shadow:
      0 3px 6px hsla(0, 0%, 0%, 0.15),
      0 2px 4px hsl(0, 0%, 0%, 0.12);
    transition: all 0.2s ease-in;
    &:hover {
      background-color: $clr-neutral-800;
    }
  }
}

//-------------------ACHIEVEMENT SECTION--------------------------

.achievement-section {
  // transform: translateY(20rem);
  margin-block-end: rem(-300);
  padding-top: rem(150);
  position: relative;
  z-index: 2;
  text-align: justify;
  hyphens: auto;

  @media (min-width: 64em) {
    margin-block-end: rem(-200);
  }
  .swiper-button-next,
  .swiper-button-prev {
    color: black;
    background-color: rgba(255, 255, 255, 0.411);
    @media (min-width: 48em) {
      color: black;
      background-color: rgba(255, 255, 255, 0.411);
    }
  }

  .swiper-button-prev {
    transform: translateX(rem(-10));
    padding: rem(30) rem(22) rem(30) rem(18);
    @media (min-width: 48em) {
      padding: rem(32) rem(32) rem(32) rem(28);
    }
  }

  .swiper-button-next {
    transform: translateX(rem(10));
    padding: rem(30) rem(18) rem(30) rem(22);
    @media (min-width: 48em) {
      padding: rem(32) rem(28) rem(32) rem(32);
    }
  }

  .swiper-button-next:after,
  .swiper-button-prev:after {
    font-size: rem(20);
    @media (min-width: 48em) {
      font-size: 2rem;
    }
  }

  .carousel {
    width: calc(100% - rem(40));
    max-width: rem(500);
    margin-inline: auto;
    margin-top: rem(60);
    &:hover {
      cursor: grab;
    }

    @media screen and (min-width: 64em) {
      max-width: rem(1200);
    }
  }

  &__carousel-wrapper {
    width: 100%;

    @media screen and (min-width: 64em) {
      display: flex;
    }

    img {
      height: 100%;
      width: 100%;
      object-fit: cover;
      display: block;
    }
  }

  &__img-wrapper {
    margin-inline: auto;
    height: rem(200);

    @media screen and (min-width: 64em) {
      flex: 1;
      max-width: unset;
      margin-inline: unset;
      height: rem(400);
    }
  }

  &__right-wrapper {
    color: white;
    display: flex;
    flex-direction: column;
    gap: rem(20);
    margin-inline: auto;
    background-color: $clr-neutral-800;
    padding: 2rem;

    @media screen and (min-width: 48em) {
      padding: 3rem;
      // height: rem(400);
    }

    @media screen and (min-width: 64em) {
      flex: 1;
      width: unset;
      max-width: unset;
      margin: unset;
      justify-content: center;
      align-items: flex-start;
      padding-left: 5rem;
      gap: 2rem;
      height: rem(400);
    }
  }

  &__title-sub {
    font-size: $fs-500;
    text-align: left;
  }

  &__text {
    color: $clr-neutral-400;
    margin-bottom: 1.5rem;
    max-width: 50ch;
  }

  &__button-second {
    display: inline;
    padding: rem(12) rem(30) rem(8);
    background-color: #fff;
    color: $clr-neutral-800;
    text-decoration: none;
  }
}

@media screen and (min-width: 48em) {
}

@media screen and (min-width: 64em) {
}

@media screen and (min-width: 80em) {
}

@media screen and (min-width: 96em) {
}

//48, 64, 80, 96
