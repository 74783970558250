@use "root" as *;
//closed menu

.hidden-nav {
  display: none;
}

.transition-font {
  font-family: "Radikal", sans-serif;
  font-size: $fs-500;
  font-weight: $fw-bold;
  line-height: 1.85;
  @media (min-width: 64em) {
    font-size: $fs-600;
  }
}

//open menu
main.active {
  .nav-bg-white {
    position: fixed;
    height: 100vh;
    width: 100vw;
    top: 0;
    left: 0;
    background-color: white;
    z-index: 12;
  }

  .nav-bg-grey {
    position: fixed;
    height: 100vh;
    width: calc((100% - rem(570)) / 2);
    top: 0;
    right: 0;
    background-color: $clr-neutral-100;
    z-index: 12;
    will-change: transform;
  }

  .hidden-nav {
    display: block;
    position: fixed;
    z-index: 12;
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: rem(60);
    .upper-wrapper {
      display: flex;
      flex-direction: column;
      gap: rem(20);

      .big-menu {
        width: calc(100vw - rem(30));
        max-width: rem(250);
        height: rem(60);
        background-blend-mode: darken;
        // border-radius: rem(3);
        overflow: hidden;

        &:nth-child(1) {
          .bg-img {
            background-image: url("../assets/img/big-menu1.jpg?as=webp");
          }
        }
        &:nth-child(2) {
          .bg-img {
            background-image: url("../assets/img/big-menu2.jpg?as=webp");
            background-position: center;
          }
        }
        &:nth-child(3) {
          .bg-img {
            background-image: url("../assets/img/big-menu3.jpg?as=webp");
          }
        }

        .bg-img {
          height: 100%;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.4);
          background-blend-mode: darken;
          // border-radius: rem(3);
          background-size: cover;
          transition: all 0.3s ease-in-out;
          &:hover,
          &:hover a {
            scale: 1.1;
          }
        }

        a {
          color: white;
          text-decoration: none;
          width: 100%;
          height: 100%;
          font-size: $fs-400;
          font-weight: $fw-medium;
          display: grid;
          place-items: center;
          transition: all 0.3s ease-in-out;

          @media screen and (min-width: 48em) {
            font-size: rem(27);
          }
        }

        @media screen and (min-width: 48em) {
          height: rem(90);
          width: calc(100vw - rem(30));
          max-width: rem(450);
        }
      }
    }

    .lower-wrapper {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      gap: rem(30);

      a {
        font-weight: $fw-medium;
        text-decoration: none;
        color: $clr-neutral-800;
        &:hover {
          color: $clr-neutral-600;
        }
      }

      @media screen and (min-width: 48em) {
        flex-direction: row;
        gap: rem(80);
        font-size: $fs-475;
      }
    }

    @media screen and (min-width: 48em) {
      gap: rem(150);
    }
  }
}

//change logo color on video page
svg.nav-active {
  fill: $clr-neutral-800 !important;
}

.logo-menu-open {
  fill: $clr-neutral-800 !important;
}

.navigation {
  &__nav {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    height: 5rem;
    z-index: 11;
    transition: 0.3s ease-in-out;

    &:after {
      transition: none;
      opacity: 0;
      content: "";
      position: absolute;
      right: 0;
      left: 0;
      bottom: 0;
      border-bottom: rem(1) solid #e9e9e9;
      z-index: 2;
    }

    &.nav-active {
      background-color: white;

      ul {
        color: $clr-neutral-800 !important;
      }

      &:after {
        transition: all 0.6s ease-in-out;
        opacity: 1;
      }
    }
  }

  &__burger-wrapper {
    position: fixed;
    right: 1rem;
    top: 1rem;
    height: 3rem;
    width: 3rem;
    display: grid;
    place-items: center;
    background-color: $clr-neutral-800;
    cursor: pointer;
    z-index: 13;

    &.active {
      .hamRotate {
        transform: rotate(45deg);
      }
      .hamRotate180 {
        transform: rotate(180deg);
      }

      .ham1 .top {
        stroke-dashoffset: -98px;
      }
      .ham1 .bottom {
        stroke-dashoffset: -138px;
      }
    }

    .ham {
      cursor: pointer;
      -webkit-tap-highlight-color: transparent;
      transition: transform 400ms;
      -moz-user-select: none;
      -webkit-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }

    .line {
      fill: none;
      transition: stroke-dasharray 400ms, stroke-dashoffset 400ms;
      stroke: white;
      stroke-width: 3.5;
      stroke-linecap: round;
    }
    .ham1 .top {
      stroke-dasharray: 40 139;
    }
    .ham1 .bottom {
      stroke-dasharray: 40 180;
    }
  }

  &__logo {
    position: fixed;
    left: rem(16);
    top: rem(20);
    height: $space-550;
    width: auto;
    z-index: 13;

    svg,
    img {
      transition: 0.3s ease-in-out;
      fill: $clr-logo;
      width: rem(45.2);
      height: rem(40);
    }
  }

  &__list {
    transition: 0.3s ease-in-out;
    gap: $space-600;
    display: none;

    a {
      font-weight: $fw-light;
      text-decoration: none;
      color: inherit;
    }
  }

  @media screen and (min-width: 48em) {
    &__list {
      display: flex;
    }
  }

  @media screen and (min-width: 64em) {
  }

  @media screen and (min-width: 80em) {
  }

  @media screen and (min-width: 96em) {
  }
}
