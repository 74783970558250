@use "root" as *;

main[videos] {
  background-color: #fbfbfb;
  // z-index: -1;
}
#shadow-host-companion {
  padding: unset !important;
}
.video-hero-section {
  background-color: $clr-neutral-800;
  height: 100vh;
  min-height: rem(800);
  clip-path: polygon(0 0, 100% 0%, 100% 76%, 0% 100%);
  position: relative;
  padding-top: rem(140);
  margin-bottom: rem(-300);

  @media (min-width: 48em) {
    padding-top: max(25vh, rem(200));
  }

  @media (min-width: 64em) {
    min-height: rem(900);
  }

  @media (min-width: 96em) {
    min-height: rem(1100);
    padding-top: rem(400);
  }
}

.videos-section {
  // margin-block-end: rem(-300);
  position: relative;
  z-index: 2;
  margin-bottom: rem(-200);
  .title-wrapper {
    margin-block-start: rem(150);
    margin-block-end: rem(100);
  }

  &__quote-wrapper {
    position: relative;
    z-index: 1;
    background-color: rgb(244, 245, 246);
    width: clamp(calc(100% - rem(40)), 20vw, rem(1200));
    margin-inline: auto;
    padding: rem(30);
    margin-bottom: rem(200);
    box-shadow:
      0 10px 20px hsla(0, 0%, 0%, 0.15),
      0 3px 6px hsl(0, 0%, 0%, 0.1);
    img {
      width: min(10%, rem(40));
      margin-inline: auto;
      margin-bottom: rem(20);
    }

    p {
      text-align: center;
      max-width: 90ch;
      margin-inline: auto;

      span {
        display: block;
        font-weight: $fw-bold;
        margin-top: rem(15);
      }
    }

    @media screen and (min-width: 48em) {
      width: min(calc(35em + 20vw), rem(800));
    }
  }

  &__wrapper {
    width: calc(100% - rem(40));
    margin-inline: auto;

    img.video-section__video {
      width: calc(100% - rem(40));
      margin-inline: auto;
    }
  }

  div[data-color="dark-grey"] {
    background-color: $clr-neutral-800;
    color: white;
  }

  div[data-color="light-grey"] {
    background-color: rgb(244, 245, 246);
    color: black;
  }

  &__training-wrapper {
    margin-top: rem(50);
    max-width: rem(500);
    margin-inline: auto;
  }

  &__text-wrapper {
    padding-block: rem(25);
    @media (max-width: 64em) {
      padding: rem(20);
      padding-block: rem(55);
    }
    p,
    h3 {
      width: calc(100% - rem(50));
      margin-inline: auto;
    }
    p {
      line-height: 1.6;
      opacity: 0.7;
      text-align: justify;
      hyphens: auto;
    }

    h3 {
      font-size: rem(20);
      font-weight: $fw-bold;
      @media (max-width: 48em) {
        margin-bottom: rem(10);
      }
    }
  }

  .video-wrapper-big {
    height: 120%;
    display: grid;
    place-items: center;
    width: 100%;
    background-position: 50%;

    background-color: $clr-neutral-700;
    background-size: cover;
    transition: all 0.3s ease-in-out;

    &[data-video="highlights"] {
      height: 105%;
      background-image: url("../assets/img/highlights.jpg?as=webp");
    }
    &[data-video="highlightsSecond"] {
      height: 105%;
      background-image: url("../assets/img/highligths2.png?as=webp");
    }
    &[data-video="athletier"] {
      height: 105%;
      background-image: url("../assets/img/athletier.jpg?as=webp");
    }
    &[data-video="dermassoeur"] {
      height: 105%;
      background-image: url("../assets/img/dermassoeur.jpg?as=webp");
    }
    &[data-video="yb-kids-camp"] {
      height: 105%;
      background-image: url("../assets/img/yb-kids.png?as=webp");
    }
    &[data-video="nachwuchspreis"] {
      height: 105%;
      background-image: url("../assets/img/nachwuchspreis.jpg?as=webp");
    }
    &[data-video="individualTraining"] {
      height: 105%;
      background-image: url("../assets/img/IndividualTraining-thumb.png?as=webp");
    }
    &:hover {
      scale: 1.05;
      rotate: -2deg;
      cursor: pointer;
    }
  }

  @media (min-width: 48em) {
    &__training-wrapper {
    }
  }

  @media (min-width: 64em) {
    &__training-wrapper {
      display: flex;
      margin-top: unset;
      max-width: rem(1100);

      justify-content: space-between;

      img {
        width: 100%;
        object-fit: cover;
        margin-bottom: unset;
      }

      .video-container {
        aspect-ratio: unset;
        height: 100%;
      }
    }

    &__footage {
      min-width: 50%;

      &.inverted {
        @media (min-width: 64em) {
          order: 2;
        }
      }
    }

    &__text-wrapper {
      padding: rem(50);
      margin-block: auto;
      h3 {
        margin-bottom: rem(10);
      }
      &.inverted {
        @media (min-width: 64em) {
          order: 1;
        }
      }
    }
  }
}
.mySwiperSecond {
  width: 75%;
  overflow: visible !important;
}
.video-master-container,
.mySwiperSecond .swiper-slide {
  max-height: rem(450);
  max-width: rem(800);
  margin-inline: auto;
  @media (min-width: 64em) {
    width: 100%;
    height: 100%;
  }
}
.video-container {
  margin-inline: auto;
  padding-bottom: 56.25%;
  overflow: hidden;
  position: relative;
  @media (min-width: 64em) {
    padding-bottom: unset;
    &#first-container {
      padding-bottom: 56.25%;
    }
  }

  .video-subContainer {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
  }
  .video-wrapper-big {
    height: 120%;
    display: grid;
    place-items: center;
    width: 100%;
    background-position: 50%;

    background-color: $clr-neutral-700;
    background-size: cover;
    transition: all 0.3s ease-in-out;

    &[data-video="highlights"] {
      height: 105%;
      background-image: url("../assets/img/highlights.jpg?as=webp");
    }

    &[data-video="athletier"] {
      height: 105%;
      background-image: url("../assets/img/athletier.jpg?as=webp");
    }
    &[data-video="yb-kids-camp"] {
      height: 105%;
      background-image: url("../assets/img/yb-kids.png?as=webp");
    }
    &[data-video="nachwuchspreis"] {
      height: 105%;
      background-image: url("../assets/img/nachwuchspreis.jpg?as=webp");
    }
    &[data-video="individualTraining"] {
      height: 105%;
      background-image: url("../assets/img/IndividualTraining-thumb.png?as=webp");
    }
    &:hover {
      scale: 1.05;
      rotate: -2deg;
      cursor: pointer;
    }
  }

  &#first-container {
    // width: calc(100% - rem(40));
  }
  img {
    position: absolute;
    top: 50%;
    left: 50%;
    translate: -50% -50%;
    width: rem(60);
    z-index: 2;
    pointer-events: none;
  }
}

.video-modal-bg {
  visibility: hidden;
  opacity: 0;
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.836);
  z-index: 999;
  backdrop-filter: blur(4px) contrast(0.8);
  transition: all 0.3s ease-in-out;

  .iframe-wrapper-wrapper {
    &.active {
      display: block;
    }
    display: none;
    max-width: rem(900);
    width: calc(100% - rem(30));
    .iframe-wrapper {
      padding-bottom: 56.25%;
      position: relative;
      width: 100%;
      height: 0;
      &.videoCustom {
        padding-bottom: 51.375%;
      }
    }
  }
  iframe {
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
  }
  img {
    width: rem(40);
    cursor: pointer;
    transform: translateY(50);
    opacity: 0;
    z-index: -1;
  }
}

iframe.active {
  display: block;
}

.video-modal-bg.active {
  visibility: visible;
  opacity: 1;
}

.videos {
  max-width: rem(1200);
  width: calc(100% - rem(30));
  height: auto;
  // aspect-ratio: 16/8.22;
  &.videoCustom {
    // aspect-ratio: 16/9;
  }
}

.title-description {
  max-width: 70ch;
}
