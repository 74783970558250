@use "../root" as *;

.title-wrapper {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  z-index: 0;
  height: rem(120);
  width: calc(100% - rem(40));
  margin-inline: auto;
  margin-block-end: rem(80);
  &[data-title="hero"] {
    .title,
    .title-story {
      font-size: clamp(rem(50), 5vw, rem(120));
    }
    .bg-title,
    .bg-title-story {
      height: clamp(rem(220), 20vw, rem(500));
      width: auto;
    }
  }
}

.bg-title,
.bg-title-story {
  display: none;
  display: block;
  z-index: 1;
  position: absolute;
  height: auto;

  width: rem(250);
  @media (min-width: 64em) {
    width: rem(300);
  }
}

.title-tag,
.title-tag-story {
  font-size: $fs-300;
  font-weight: $fw-light;
  color: $clr-neutral-400;
  margin-bottom: calc(-1 * $space-200);
  z-index: 2;
  text-transform: uppercase;
  // text-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.12), 0 1px 2px hsl(0, 0%, 0%, 0.24);
  @media (min-width: 64em) {
    font-size: $fs-450;
  }
}

.title,
.title-story {
  font-size: $fs-550;
  z-index: 2;
  font-weight: $fw-bold;
  text-align: center;
  line-height: 1;
  margin-top: 0.3rem;
  position: relative;
  // text-shadow: 0 1px 3px hsla(0, 0%, 0%, 0.12), 0 1px 2px hsl(0, 0%, 0%, 0.24);
  &[dark] {
    color: $clr-neutral-000;
  }
  @media (min-width: 64em) {
    font-size: $fs-600;
  }
}

.hide-animation {
  transform: skew(-14deg);
  position: absolute;
  // left: 50%;
  // translate: calc(-54% - 0.8vw) -50%;
  // top: 50%;
  scale: 0;
  width: 30%;
  height: clamp(7.5rem, 15vw, 16.875rem);
  min-width: rem(201);
  background-color: $clr-neutral-000;
}
.go-down {
  padding-top: 8vw;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  gap: rem(10);
  @media (min-width: 48em) {
    display: none;
  }
  // @media (min-width: 80em) {
  //   padding-top: rem(40);
  // }
  // @media (min-width: 96em) {
  //   padding-top: rem(100);
  // }
}
