@use "../root" as *;

.contact-section {
  position: relative;
  padding-top: 25rem;
  padding-bottom: 14rem;
  z-index: 1;

  &[more-infos] {
    &::before {
      background-color: $clr-neutral-800;
    }

    .title {
      color: white;
    }

    .contact-section__button-primary {
      &:hover {
        background-color: $clr-neutral-000;
        color: black;
      }
    }
  }

  &::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: $clr-neutral-100;
    clip-path: polygon(0 26%, 100% 0, 100% 100%, 0% 100%);
    z-index: -2;
  }

  &__description {
    color: $clr-neutral-400;
    width: calc(100% - rem(40));
    text-align: center;
    line-height: 1.5;
    margin-block: 2rem;
    max-width: 64ch;
  }

  &__button-primary {
    text-decoration: none;
    color: white;
    background-color: $clr-primary-400;
    padding: rem(27) rem(40) rem(25);
    line-height: 0;
    box-shadow: 0 3px 6px hsla(0, 0%, 0%, 0.15), 0 2px 4px hsl(0, 0%, 0%, 0.12);
    transition: all 0.2s ease-in;

    &:hover {
      background-color: $clr-neutral-800;
    }
  }
}

footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2rem 0;
  font-weight: $fw-light;
  position: relative;
  z-index: 1;
  background-color: white;

  :nth-child(1) {
    order: 2;
    margin: 2rem 0;
  }

  :nth-child(2) {
    order: 1;
    max-width: rem(40);
  }

  :nth-child(3) {
    order: 3;
  }

  @media screen and (min-width: 48em) {
    flex-direction: row;
    justify-content: space-between;
    padding: 0 2rem;

    :nth-child(1) {
      order: 1;
      margin: 2rem 0;
    }

    :nth-child(2) {
      order: 1;
      max-width: rem(40);
    }

    :nth-child(3) {
      order: 3;
    }
  }
}
