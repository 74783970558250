@use "./root" as *;

main[contact] {
  #content {
    min-height: 100vh;
  }

  .title-wrapper {
    &[data-title="hero"] {
      .title {
        font-size: clamp($fs-600, 4vw, rem(50));
      }
      .bg-title {
        height: clamp(rem(150), 20vw, rem(250));
      }
    }
  }

  .mailToA {
    color: black;
    text-decoration: none;
    font-weight: $fw-light;
  }
  .mailto {
    width: fit-content;
    position: relative;
    padding: rem(10) rem(20) rem(10) rem(50);
    border: 1px dashed $clr-neutral-200;
    border-radius: 3px;
    background-color: hsl(240, 14%, 99%);
    margin-right: auto;
    margin-top: rem(20);
    @media (min-width: 30em) {
      margin-left: auto;
      margin-top: unset;
      margin-right: unset;
    }

    a {
    }
  }
}

.contact-hero-section {
  min-height: rem(500);
  padding-top: rem(150);

  @media (min-width: 64em) {
    padding-top: rem(200);
  }

  p {
    width: calc(100% - rem(40));
    max-width: 70ch;
    text-align: center;
    margin-inline: auto;
    margin-block-start: 6vw;
  }

  .input-wrapper {
    display: flex;
    flex-direction: column;
  }

  .form-wrapper {
    display: grid;
    width: min(calc(100% - rem(40)), rem(600));
    max-width: rem(600);
    margin-inline: auto;
    gap: rem(20);
    margin-block-start: max(6vw, rem(132));
    margin-block-end: rem(200);
    grid-template-areas:
      "vorname"
      "nachname"
      "email"
      "telefon"
      "message";

    @media (min-width: 30em) {
      grid-template-areas:
        "vorname nachname"
        "email telefon"
        "message message";
    }

    @media (min-width: 64em) {
      margin-block-end: rem(100);
    }

    .input-wrapper:nth-child(1) {
      grid-area: vorname;
    }

    .input-wrapper:nth-child(2) {
      grid-area: nachname;
    }

    .input-wrapper:nth-child(3) {
      grid-area: email;
    }

    .input-wrapper:nth-child(4) {
      grid-area: telefon;
    }

    .input-wrapper:nth-child(5) {
      grid-area: message;
    }

    textarea {
      padding-top: rem(10);
      margin-block-end: rem(20);
      resize: vertical;
    }

    label {
      font-weight: $fw-light;
    }

    input,
    textarea {
      border: none;
      background-color: $clr-neutral-100;
      padding-left: rem(10);
      width: 100%;
    }

    input {
      height: rem(40);
    }

    button {
      cursor: pointer;
      border: none;
      width: fit-content;
      padding: rem(15) rem(40);
      background-color: $clr-primary-400;
      color: $clr-neutral-000;
      box-shadow: 0 3px 6px hsla(0, 0%, 0%, 0.15),
        0 2px 4px hsl(0, 0%, 0%, 0.12);
      transition: all 0.2s ease-in;

      &:hover {
        background-color: $clr-neutral-800;
      }
    }
  }
}

footer[contact] {
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  border-top: 1px solid $clr-neutral-200;
}
