@use "root" as *;

.story-hero-section {
  position: relative;

  height: 80vh;
  min-height: rem(500);
  padding-top: rem(140);
  @media (min-width: 48em) {
    height: 100vh;
  }

  @media (min-width: 48em) {
    padding-top: max(25vh, rem(200));
  }

  @media (min-width: 96em) {
    min-height: rem(1000);
    padding-top: rem(400);
  }
}

//Story section
.story-section {
  width: calc(100% - rem(40));
  margin-inline: auto;
  position: relative;
  z-index: 2;
  overflow-x: hidden;

  .observedCount {
    will-change: transform;
    @media (min-width: 48em) {
      margin-bottom: rem(200);
      margin-top: rem(300);
    }
  }

  #first-wrapper {
    @media (min-width: 48em) {
      margin-bottom: rem(200);
      margin-top: unset;
    }
  }

  .bg-title {
    width: rem(300);
    height: auto;
    @media (min-width: 48em) {
      width: rem(350);
    }
  }
  .title-tag {
    font-size: rem(17);
    margin-bottom: rem(-4);
    @media (min-width: 48em) {
      font-size: rem(18);
      margin-bottom: rem(-4);
    }
  }
  .title {
    font-size: rem(40);
    @media (min-width: 48em) {
      font-size: rem(60);
    }
  }

  .img-container {
    position: relative;
    overflow: hidden;

    .white-cover {
      position: absolute;
      inset: 0;
      background-color: white;
      z-index: 999;
      will-change: transform;
    }
  }

  .parallax-container {
    position: relative;
    overflow: hidden;
    height: 95%;
    top: 50%;
    transform: translateY(-50%);
    z-index: 1;

    img {
      height: 120%;
      width: 100%;
      object-fit: cover;
      position: absolute;
      z-index: 1;
    }
  }
  &__wrapper {
    margin-bottom: 10rem;
    width: min(100% - 2rem, rem(900));
    margin-inline: auto;

    p {
      text-align: center;
      max-width: 80ch;
      margin-inline: auto;
      margin-block-end: 4rem;
      margin-block-start: rem(30);
      will-change: transform;
      line-height: 1.8;

      &#special-p {
        @media (max-width: 40em) {
          max-width: 25ch;
        }
      }
    }
  }

  &__year2005 {
    .img-wrapper {
      display: grid;
      gap: rem(20);
      grid-template-areas:
        "a a a"
        "b b b";
      grid-template-columns: 1fr 40% 1fr;
      grid-template-rows: calc(rem(200) + 5vw) calc(rem(200) + 5vw);

      .img-container:nth-child(1) {
        grid-area: a;
        width: 100%;
      }
      .img-container:nth-child(2) {
        width: 100%;
        grid-area: b;
      }

      @media screen and (min-width: 48em) {
        grid-template-rows: rem(150) rem(150);

        grid-template-areas:
          "a b b"
          "a b b";
      }
    }
  }

  &__year2013 {
    position: relative;
    .img-wrapper {
      display: grid;
      gap: rem(20);
      row-gap: rem(10);
      width: 100%;
      grid-template-areas:
        "a a"
        "b d"
        "c c";
      margin-top: 1rem;
      margin-bottom: 2rem;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: rem(250) rem(350) rem(350);
      @media screen and (min-width: 48em) {
        grid-template-columns: 0.8fr 0.2fr 1fr 1fr 0.2fr 0.8fr;
        grid-template-rows: rem(280) rem(300);
        grid-template-areas:
          ". a a a a ."
          "b b c c d d";
      }

      .img-container:nth-child(1) {
        width: 100%;
        grid-area: a;
      }

      .img-container:nth-child(2) {
        grid-area: b;
      }

      .img-container:nth-child(3) {
        grid-area: c;
      }

      .img-container:nth-child(4) {
        grid-area: d;
      }
    }
  }

  &__year2016 {
    position: relative;
    .img-wrapper {
      display: grid;
      gap: rem(20);
      grid-template-areas:
        "a a"
        "b b";
      margin-top: 1rem;
      margin-bottom: 2rem;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: rem(250) rem(350);
      @media screen and (min-width: 48em) {
        grid-template-columns: 0.5fr 0.5fr 1fr 1fr 0.5fr 0.5fr;
        grid-template-rows: rem(320) rem(450);
        grid-template-areas:
          ". a a a a ."
          "b b b b b b";
      }

      .img-container:nth-child(1) {
        grid-area: a;
      }

      .img-container:nth-child(2) {
        grid-area: b;
      }

      img {
        width: 100%;
      }
    }
  }

  &__year2017 {
    .img-wrapper {
      display: grid;
      gap: rem(20);
      grid-template-areas:
        "a a"
        "b b";
      margin-top: 1rem;
      margin-bottom: 2rem;
      grid-template-columns: 1fr 1fr;
      grid-template-rows: rem(250) rem(350);
      @media screen and (min-width: 48em) {
        grid-template-columns: 0.5fr 0.5fr 1fr 1fr 0.5fr 0.5fr;
        grid-template-rows: rem(320) rem(400);
        grid-template-areas:
          ". a a a a ."
          "b b b b b b";
      }

      .img-container:nth-child(1) {
        grid-area: a;
      }

      .img-container:nth-child(2) {
        grid-area: b;
      }

      img {
        width: 100%;
      }
    }
  }

  &__year2018 {
    position: relative;
    .img-container {
      height: rem(250);
      @media screen and (min-width: 48em) {
        height: rem(350);
      }
    }

    // .parallax-container {
    //   width: 100%;
    //   height: rem(300);
    //   @media screen and (min-width: 48em) {
    //     height: rem(400);
    //   }
    // }
  }

  &__yearYB {
    .img-wrapper {
      margin-top: 1rem;
      margin-bottom: 2rem;
      display: grid;
      gap: rem(20);
      grid-template-areas:
        "a a"
        "b d"
        "c c";
      grid-template-columns: 1fr 1fr;
      grid-template-rows: rem(200) rem(400) rem(400);

      @media screen and (min-width: 48em) {
        grid-template-areas:
          ". a a a a ."
          "b b c c d d";
        grid-template-columns: 0.7fr 0.3fr 1fr 1fr 0.3fr 0.7fr;
        grid-template-rows: rem(200) rem(500);
      }

      .img-container:nth-child(1) {
        grid-area: a;
      }

      .img-container:nth-child(2) {
        grid-area: b;
      }

      .img-container:nth-child(3) {
        grid-area: c;
      }

      .img-container:nth-child(4) {
        grid-area: d;
      }
    }
  }

  &__year2021 {
    position: relative;
    #last-img {
      height: rem(170);
      margin-top: rem(220);
      margin-bottom: rem(-100);
      @media screen and (min-width: 48em) {
        height: rem(400);
      }
    }
    .img-container {
      width: 100%;
      height: rem(200);
    }
    .upper-wrapper {
      .img-container {
        height: rem(500);
        width: rem(400);
        margin-inline: auto;
        max-width: 100%;
      }
    }
    p {
      margin-top: rem(30);
    }
    @media screen and (min-width: 48em) {
      .img-container {
        width: 100%;
        height: rem(300);
      }
      .upper-wrapper {
        display: flex;
        align-items: center;
        gap: rem(30);
        width: 80%;
        margin-inline: auto;
        margin-bottom: 5rem;

        .img-container {
        }
        p {
          text-align: start;
        }
      }
    }
  }

  &__year2023 {
    position: relative;
    #last-img {
      height: rem(170);
      margin-top: rem(220);
      margin-bottom: rem(-100);
      @media screen and (min-width: 48em) {
        height: rem(400);
      }
    }

    p {
      margin-top: rem(30);
    }
    .video-wrapper {
      width: 100%;
      display: flex;
      justify-content: center;
    }
    video {
      width: rem(500);
      height: rem(666);
      max-width: 100%;

      @media screen and (max-width: rem(600)) {
        aspect-ratio: 3 / 4;
        width: unset;
        height: unset;
      }
    }
    @media screen and (min-width: 48em) {
      p {
        text-align: center;
      }
    }
  }

  #deco1 {
    position: absolute;
    top: rem(-500);
    left: rem(200);
    z-index: -1;
    @media screen and (min-width: 48em) {
    }
  }
  #deco2 {
    position: absolute;
    top: rem(-350);

    z-index: -1;
    @media screen and (min-width: 48em) {
    }
  }
  #deco3 {
    position: absolute;
    top: rem(-200);
    left: rem(200);
    z-index: -1;
    @media screen and (min-width: 48em) {
    }
  }
  #deco4 {
    position: absolute;
    top: rem(-300);
    right: rem(150);
    z-index: -1;
    @media screen and (min-width: 48em) {
    }
  }
  #deco5 {
    position: absolute;
    top: rem(-400);
    right: rem(50);
    z-index: -1;
    @media screen and (min-width: 48em) {
    }
  }
  #deco6 {
    position: absolute;
    top: rem(-500);
    right: rem(50);
    z-index: -1;
    @media screen and (min-width: 48em) {
    }
  }
  #deco7 {
    position: absolute;
    top: rem(-600);
    left: rem(-50);

    z-index: -1;
    @media screen and (min-width: 48em) {
    }
  }

  #deco8 {
    position: absolute;
    top: rem(-2300);
    rotate: -90deg;
    right: rem(0);
    z-index: -1;
    @media screen and (min-width: 48em) {
      top: rem(-1000);
      right: rem(-400);
    }
  }

  @media screen and (min-width: 48em) {
  }

  @media screen and (min-width: 64em) {
  }

  @media screen and (min-width: 80em) {
  }

  @media screen and (min-width: 96em) {
  }
}

.story-section + .contact-section {
  margin-top: -25rem;
}
