@use "root" as *;
/* Utility classes */

.container {
  width: calc(100% - rem(40));
  margin-inline: auto;
}

.text-neutral-400 {
  color: $clr-neutral-400;
}

.text-neutral-800 {
  color: $clr-neutral-800;
}

.bg-neutral-200 {
  background-color: $clr-neutral-200;
}

.m-block-end-400 {
  margin-block-end: rem(50);
}

.text-center {
  text-align: center;
  margin-inline: auto;
}

.row {
  display: flex;
  flex-direction: row;
  gap: rem(80);
}
