@use "sass:math";

@function rem($pixel) {
  @if math.is-unitless($pixel) {
    @return math.div($pixel, 16) + rem;
  } @else {
    @error 'Don\'t use units when using rem()';
  }
}

$clr-neutral-000: hsl(0, 0%, 100%);
$clr-neutral-100: hsl(180, 3%, 94%);
$clr-neutral-200: hsl(220, 6%, 90%);
$clr-neutral-400: hsl(217, 6%, 57%);
$clr-neutral-600: hsl(214, 8%, 33%);
$clr-neutral-700: hsl(214, 9%, 15%);
$clr-neutral-800: hsl(216, 8%, 12%);

$clr-logo: white;

$clr-primary-400: #3e4248;
$clr-primary-200: hsl(200, 14%, 96%);

$clr-accent-100: hsl(198, 60%, 37%);
$clr-accent-200: hsl(166, 60%, 38%);

$ff-primary: "Radikal", sans-serif;
$ff-body: $ff-primary;
$ff-heading: $ff-primary;

$fw-thin: 200;
$fw-light: 300;
$fw-regular: 400;
$fw-medium: 500;
$fw-bold: 700;
$fw-black: 900;

$fs-300: rem(14);
$fs-400: rem(16);
$fs-450: rem(18);
$fs-475: rem(21);
$fs-500: rem(24);
$fs-550: rem(32);
$fs-600: rem(40);
$fs-625: rem(48);
$fs-650: rem(56);
$fs-700: rem(72);
$fs-800: rem(90);
$fs-900: rem(185);

$space-100: rem(4);
$space-200: rem(8);
$space-300: rem(12);
$space-400: rem(20);
$space-500: rem(32);
$space-550: rem(40);
$space-600: rem(52);
$space-700: rem(84);

body,
html {
  font-family: $ff-primary;
  height: 100%;
}

main {
  position: relative;
  overflow-y: hidden;
  width: 100%;
  background-color: white;
}

section {
  padding-top: rem(80);
  padding-bottom: rem(80);
}

p,
span {
  font-weight: 300;
}

#content {
  will-change: background-color;
}

.black-cover {
  background-color: #1c1e21;
  position: fixed;
  height: 100vh;
  width: 100%;
  z-index: 100;
  display: flex;
  justify-content: center;
  align-items: center;
  will-change: transform;

  span {
    display: none;
    color: white;
    font-size: $fs-550;
    font-weight: $fw-bold;

    &.once-active {
      // height: rem(80);
      display: block;
      background-color: $clr-neutral-800;
      z-index: 10;
      // max-width: 23ch;
      text-align: center;
      font-family: "CutiveMono";
      font-size: $fs-450;
      @media (min-width: 48em) {
        font-size: $fs-500;
      }
    }
    &.author {
      display: block;
      opacity: 0;
      text-align: center;
      font-family: "CutiveMono";
      font-size: $fs-450;
      @media (min-width: 48em) {
        font-size: $fs-500;
      }
    }
    @media (min-width: 48em) {
      font-size: $fs-600;
    }
  }

  .unactive {
    span.active {
      display: none;
    }
  }

  span.active {
    display: block;
  }
}
.words-wrapper {
  overflow: hidden;
  position: absolute;
  will-change: opacity, visibility, transform;
}
.words {
  width: 100vw;

  height: rem(50);
  position: relative;
  display: flex;
  justify-content: center;
  will-change: transform;
  div {
    position: absolute;
  }
}

.enter-a {
  will-change: visibility, opacity, transform;
}

body.blocked {
  overflow: hidden !important;
}
