@font-face {
  font-family: "Radikal";
  src: url("../assets/fonts/Radikal-thin.otf") format("opentype");
  font-weight: 200;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "Radikal";
  src: url("../assets/fonts/Radikal-light.otf") format("opentype");
  font-weight: 300;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "Radikal";
  src: url("../assets/fonts/Radikal-regular.otf") format("opentype");
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "Radikal";
  src: url("../assets/fonts/Radikal-medium.otf") format("opentype");
  font-weight: 500;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "Radikal";
  src: url("../assets/fonts/Radikal-bold.otf") format("opentype");
  font-weight: 700;
  font-style: normal;
  font-display: auto;
}

@font-face {
  font-family: "Radikal";
  src: url("../assets/fonts/Radikal-black.otf") format("opentype");
  font-weight: 900;
  font-style: normal;
  font-display: auto;
}
@font-face {
  font-family: "CutiveMono";
  src: url("../assets/fonts/CutiveMono-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: auto;
}
